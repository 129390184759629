import React from "react";

// MUI
import {styled, useMediaQuery, useTheme, Box, css} from "@mui/material";

// Custom components
import ItemList, {ItemsTwoCol} from "../../components/ItemList";

// data
import tokensAndCoinsData from "../../data/tokensAndCoins.json";
import tokensTypeData from "../../data/tokensType.json";

import Muofit from "../Home/Muofit";

export default function Information() {
  const mTheme = useTheme();
  const isMobile = useMediaQuery(mTheme.breakpoints.down("md"));

  const paddingSize = isMobile ? "0px 24px" : "0px 160px";

  return (
    <ContentContainer isMobile={isMobile}>
      <Heading>Information</Heading>

      {/* Intro About mu */}
      <Box padding={paddingSize}>
        <h2 className="generic_title">Intro About mu</h2>
        <div className="intro__content">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/3bvJ7e9KbqU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </Box>

      {/* Tokens & Coins */}
      <Box padding={paddingSize}>
        <h2 className="generic_title">Tokens & Coins</h2>

        <ItemsTwoCol isMobile={isMobile}>
          <ItemList
            contentType={"list"}
            title={"Tokens"}
            data={tokensAndCoinsData.tokens}
          />
          <ItemList
            contentType={"list"}
            title={"Coins"}
            data={tokensAndCoinsData.coins}
          />
        </ItemsTwoCol>

        <Link
          href="https://crypto.com/university/crypto-tokens-vs-coins-difference"
          rel="noreferrer"
          target="_blank"
        >
          Source:
          https://crypto.com/university/crypto-tokens-vs-coins-difference
        </Link>
      </Box>

      {/* Difference of Tokens */}
      <Box padding={paddingSize}>
        <h2 className="generic_title">Difference of Tokens</h2>

        <ItemsTwoCol isMobile={isMobile}>
          <ItemList
            contentType={"text"}
            title={"Utility Tokens"}
            data={tokensTypeData.utilityTokens}
          />
          <ItemList
            contentType={"text"}
            title={"Equity Tokens"}
            data={tokensTypeData.equityTokens}
          />
        </ItemsTwoCol>

        <Link
          href="https://blog.liquid.com/whats-the-difference-between-security-tokens-and-utility-tokens#Whatareutilitytokens"
          rel="noreferrer"
          target="_blank"
        >
          Source:
          https://blog.liquid.com/whats-the-difference-between-security-tokens-and-utility-tokens#Whatareutilitytokens
        </Link>
      </Box>

      <Muofit/>
    </ContentContainer>
  );
}

const ContentContainer = styled(Box)`
  display: grid;
  gap: 96px;

  ${({isMobile}) =>
          isMobile &&
          css`
            gap: 48px;
          `}
`;

const Heading = styled("h1")`
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 48px;
  text-align: center;
  color: #ffffff;
  margin-top: 48px;
`;

const Link = styled("a")`
  display: block;
  text-decoration: none;
  color: #9c9c9c;
  margin-top: 24px;
`;