import { FaMinus, FaPlus } from "react-icons/fa";

import { IconButton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

export default function SAccordion({
  expanded,
  setExpanded,
  id,
  summary,
  detail,
}) {
  const isExpanded = expanded === `panel-${id}`;

  const expandIcons = (
    <IconButton>
      {isExpanded ? (
        <FaMinus className="text-base text-[#19e4cc] md:text-lg lg:text-xl" />
      ) : (
        <FaPlus className="text-base text-[#19e4cc] md:text-lg lg:text-xl" />
      )}
    </IconButton>
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={isExpanded}
        onChange={handleChange(`panel-${id}`)}
        className="!rounded-xl transition-all [&.MuiAccordion-root]:bg-[#2a2b2d] [&.MuiAccordion-root]:text-white"
      >
        <AccordionSummary
          expandIcon={expandIcons}
          className="px-6 md:px-8 [&>div]:my-4 md:[&>div]:my-6"
        >
          <Typography className="!font-bold md:!text-lg lg:!text-xl uppercase">
            {id + ". " + summary}
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="!pl-12 !pt-0 !pb-6 !lg:pb-8 !lg:pl-14">
          {detail}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
