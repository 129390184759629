import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Information from "./pages/Information/Information";
import Error404 from "./pages/404/404";
import PageLayout from "./layouts/PageLayout/PageLayout";
import "./App.css";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PageLayout />}>
          {/* Home */}
          <Route path="/" element={<Home />} />

          {/* Information */}
          <Route path="/information" element={<Information />} />
        </Route>
        {/* Error 404 */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
}
