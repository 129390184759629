import React from "react";
import { Box, css, styled } from "@mui/material";

export default function ItemList({ title, data, contentType }) {
  return (
    <Column>
      <Box className="title">{title}</Box>
      <Box className="content">
        {/* If content is a just text */}
        {contentType === "text" && <p>{data}</p>}

        {/* If content is a list */}
        {contentType === "list" &&
          data.map((item, index) => (
            <ul key={index}>
              <li>{item}</li>
            </ul>
          ))}

        {/* If content is an object */}
        {contentType === "object" &&
          data.map((item, index) => (
            <div key={index}>
              <h6>{item.title}</h6>
              <p>{item.text}</p>
            </div>
          ))}
      </Box>
    </Column>
  );
}

const Column = styled(Box)`
  & .title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 40px;
    letter-spacing: -2px;
    color: #ffffff;
    margin-bottom: 32px;
  }

  & .content {
    display: grid;
    gap: 32px;

    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;

    & h6 {
      font-weight: 800;
      margin-bottom: 8px;
      font-size: 20px;
    }

    & ul {
      list-style-position: inside;
    }
  }
`;

export const ItemsTwoCol = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 64px;

  margin-top: 48px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      grid-template-columns: 1fr;
      gap: 32px;
    `}
`;
