import { styled } from "@mui/material/styles";
import HubspotForm from "react-hubspot-form";
import React from "react";
import { Box, css, useMediaQuery, useTheme } from "@mui/material";

const Muofit = () => {
  const mTheme = useTheme();
  const isMobile = useMediaQuery(mTheme.breakpoints.down("md"));
  return (
    <div className="muofit">
      <div className="muofit__outlineText">ARE YOU MU OF IT?</div>
      <div className="muofit__normalText">Join the early muvement!</div>

      <HubspotFormWrapper isMobile={isMobile}>
        <HubspotForm
          portalId="3948482"
          formId="1f141b91-6a0e-4694-a883-a4cf8c7e4d06"
          onSubmit={() => console.log("Submit!")}
          onReady={() => console.log("Form ready!")}
          loading={<div className="loading">Loading the form...</div>}
        />
      </HubspotFormWrapper>
    </div>
  );
};

export default Muofit;

const HubspotFormWrapper = styled(Box)`
  padding: 62px 100px 32px 100px;
  position: relative;
  overflow: hidden;

  background: #232526;
  background: -webkit-linear-gradient(to top, #ffffff, #ffffff);
  background: linear-gradient(to top, #ffffff, #ffffff);
  box-shadow: rgba(0, 0, 0, 0.2) 0 7px 29px 0;

  :before {
    content: "";
    position: absolute;
    top: 36px;
    left: -130px;
    width: 260px;
    height: 260px;
    border-radius: 260px;
    background: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.01) 24.01%,
      rgba(25, 228, 204, 0.4) 71.35%
    );
    transform: rotate(-90deg);
  }

  :after {
    content: "";
    position: absolute;
    top: 20px;
    right: -130px;
    width: 260px;
    height: 260px;
    border-radius: 260px;
    background: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.01) 24.01%,
      rgba(25, 228, 204, 0.4) 71.35%
    );
    transform: rotate(90deg);
  }

  border-radius: 12px;
  margin-top: 48px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      :before {
        content: none;
      }
      padding: 32px 32px 0;
      border-radius: 8px;
    `}
  & .loading {
    text-align: center;
    padding-bottom: 32px;
    color: #000;
  }

  & div {
    z-index: 10;
    position: relative;
  }
`;
