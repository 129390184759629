import { Box, css, styled, useMediaQuery, useTheme } from "@mui/material";
import Components from "./Components";
import HeroSection from "./HeroSection";
import Intro from "./Intro";
import Muofit from "./Muofit";
import Solutions from "./Solutions";
import FAQ from "./FAQ";

export default function Home() {
  const mTheme = useTheme();
  const isMobile = useMediaQuery(mTheme.breakpoints.down("md"));

  return (
    <ContentContainer isMobile={isMobile}>
      <HeroSection />
      <Solutions />
      <Components />
      <Intro />
      <FAQ />
      <Muofit />
    </ContentContainer>
  );
}

const ContentContainer = styled(Box)`
  display: grid;
  gap: 96px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      gap: 48px;
    `}
`;
