import React from "react";
import { Box, styled } from "@mui/material";

import Logo from "../../assets/img/logo.svg";

export default function Header() {
  const show = window.location.pathname === "/" ? false : true;

  return (
    show && (
      <Wrapper>
        <a href="/">
          <img src={Logo} alt="MU Protocol" />
        </a>
      </Wrapper>
    )
  );
}

const Wrapper = styled(Box)`
  display: grid;
  place-items: center;
  height: 80px;

  & a {
    display: flex;
  }
`;
