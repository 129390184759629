import ComponentItem from "./ComponentItem";
import theMuWay from "../../assets/img/theMuWay.svg";
import {MuExecutor, MuMarketplace, MuToolkit, MuSmartContract} from "../../assets/icons"

const componentsData = [
  {
    title: "Mu Executor",
    icon: MuExecutor,
    items: [
      "Run by providers",
      "Turns any server into a Mu node",
      "Easy to configure, deploy and run safely",
      "Supports: serverless WASM runtime, serverless database, gateway & decentralised storage engine",
      "High performance (built on Rust)",
      "Free & open source",
    ],
  },
  {
    title: "Mu Marketplace",
    icon: MuMarketplace,
    items: [
      "For Users, developers & providers",
      "All providers are listed here",
      "Developers can find providers & regions to deploy their apps",
      "Simple, decentralized & seamlessly accessible by our CLI or our UI",
    ],
  },
  {
    title: "Mu Toolkit",
    icon: MuToolkit,
    items: [
      "For developers and providers to interact with Mu",
      "Easy to install",
      "Contains tools to develop Mu apps as well as configure Mu nodes",
      "Supports multiple platforms & languages",
      "Free & open source",
    ],
  },
  {
    title: "Mu Smart Contract",
    icon: MuSmartContract,
    items: [
      "Heart of the Mu marketplace",
      "Acts as a broker between providers & developers",
      "Guarantees that providers offer a high quality service",
      "Supports multiple tokens and blockchains*",
      "Open source and audited",
    ],
  },
];

const Components = () => {
  return (
    <div className="components">
      <div className="generic_title">The mu Way</div>

      <div className="components__diagram">
        <img src={theMuWay} width="100%" height="auto" alt="the Mu way" />
      </div>

      <div className="components__content">
        {
          componentsData.map(component => <ComponentItem class={"one"} data={component} />)
        }
      </div>
    </div>
  );
};

export default Components;
