const ComponentItem = props => {
    return (
        <div className="components--content__item">
            <h3>
              <img src={props.data.icon} alt={props.data.title}/>
              <span>{props.data.title}</span>
            </h3>
            <ul>
                {
                    props.data.items.map(
                        (item, index) => <li key={index}>{item}</li>
                    )
                }
            </ul>
        </div>
    );
}

export default ComponentItem;