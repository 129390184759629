import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

// Custom components
import ItemList, { ItemsTwoCol } from "../../components/ItemList";

const providersData = [
  {
    title: "Stand up to the big ones",
    text: "Now you can offer your infrastructure and the same services as the big cloud providers.",
  },
  {
    title: "Reach a bigger market",
    text: "Use our marketplace to announce your services to developers from all the world.",
  },
  {
    title: "Offer cloud services without pain",
    text: "Being a cloud player has never been easier run a single binary in your servers and you're there! Easy to maintain due to the powerful CLI tools.",
  },
];

const developersData = [
  {
    title: "The cloud that scales",
    text: "With hundreds of different providers, capacity is not limited to a single provider or a single datacenter, but entirely independent.",
  },
  {
    title: "The resilient cloud",
    text: "Now you can isntantly deploy your apps to several providers for REAL redundancy, without vendor lock-in. ",
  },
  {
    title: "100% locally sourced cloud goodness!",
    text: "Use local providers, support your local economy, by an ease of a click.",
  },
];

const Solutions = () => {
  const mTheme = useTheme();
  const isMobile = useMediaQuery(mTheme.breakpoints.down("md"));

  return (
    <div className="solutions">
      <div className="generic_title">The Solutions</div>

      <ItemsTwoCol isMobile={isMobile}>
        <ItemList
          contentType={"object"}
          title={"For Providers"}
          data={providersData}
        />
        <ItemList
          contentType={"object"}
          title={"For Developers"}
          data={developersData}
        />
      </ItemsTwoCol>
    </div>
  );
};

export default Solutions;
