import { useState } from "react";

import SAccordion from "../../components/SAccordion";

const data = [
  {
    summary: "What is mu?",
    detial: (
      <p>
        mu Protocol is a distributed, decentralized cloud provider marketplace.
        mu allows infrastructure providers to offer their servers for sale in a
        standardized and secure way. Apps designed for mu can work with any
        mu-enabled provider, which removes the vendor lock from most of
        today&apos;s cloud providers.
        <br />
        <br />
        By creating a standardized way to provide and consume cloud technology,
        mu allows clients and providers to create a healthier and more
        competitive ecosystem, where apps can be easily developed and tested
        locally, and deployed with the same ease to the cloud.
        <br />
        <br />
        mu makes developer&apos;s lives considerably easier by providing a
        single, open standards-based and simple way to develop, test and deploy
        serverless applications. Developers need not worry anymore where they
        will be deploying their application, all they have to do is to implement
        it against the mu SDK and test it locally, where it will work exactly
        the same way as it would in the cloud. After the application is ready to
        be deployed, they simply choose a provider and a region from the mu
        Marketplace and deploy it.
      </p>
    ),
  },
  {
    summary: "How to get started?",
    detial: (
      <p>
        Take a look at the{" "}
        <a
          href="https://beta-access-solana.muprotocol.io/#quick-start"
          target="_blank"
          rel="noreferrer"
          className="link no-underline"
        >
          Getting Started
        </a>{" "}
        section of this page!{" "}
      </p>
    ),
  },
  {
    summary: "Where are the servers located?",
    detial: (
      <p>
        Servers can be located anywhere in the world, and their location depends
        on the service providers currently offering services in the mu
        marketplace. mu Protocol itself has servers in Frankfurt, New York,
        Sydney and Singapore.
      </p>
    ),
  },
  {
    summary: "How much does mu cost?",
    detial: (
      <p>
        Pricing varies across providers since each provider can set their own
        prices. Be sure to check the prices by running{" "}
        <span className="bg inline rounded bg-mu-text px-2 py-[2px] font-mono text-mu-primary">
          mu list region
        </span>{" "}
        before deploying to a region.
      </p>
    ),
  },
  {
    summary: "How can I become a mu provider?",
    detial: (
      <p>
        If you&apos;re a cloud service provider interested in offering mu
        hosting,{" "}
        <a
          href="mailto:invest@petitcode.com"
          target="_blank"
          rel="noreferrer"
          className="link no-underline"
        >
          contact us
        </a>
        .
      </p>
    ),
  },
];

export default function FAQ() {
  const [expanded, setExpanded] = useState(false);

  return (
    <section id="faq" className="faq grid gap-8">
      <div className="generic_title">FAQ</div>

      <div className="grid gap-4">
        {data.map((accordion, index) => {
          return (
            <SAccordion
              key={index}
              expanded={expanded}
              setExpanded={setExpanded}
              id={index + 1}
              summary={accordion.summary}
              detail={accordion.detial}
            />
          );
        })}
      </div>
    </section>
  );
}
