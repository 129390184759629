import Logo from "../../assets/img/logo.svg";
import HeroVideo from "../../assets/server.mp4";
import HeroImage from "../../assets/img/hero.jpg";
import PartnersMarquee from "./PartnersMarquee";

const HeroSection = () => {
  return (
    <div className="hero">
      <video
        playsInline
        autoPlay
        muted
        loop
        poster={HeroImage}
        className="hero__video"
      >
        <source src={HeroVideo} type="video/mp4" />
      </video>
      <div className="hero__content">
        <a href="/" className="hero__logo">
          <img src={Logo} alt="MU Protocol" />
        </a>
        <div className="hero__text">
          The instant
          <br />
          Cloud Service.
        </div>

        <div className="hero__btn--wrapper">
          <a
            href="https://beta-access-pwr.muprotocol.io"
            target="_blank"
            rel="noreferrer"
            className="hero__btn"
          >
            PWR BETA ACCESS
          </a>
          <a
            href="https://beta-access-solana.muprotocol.io"
            target="_blank"
            rel="noreferrer"
            className="hero__btn"
          >
            SOLANA BETA ACCESS
          </a>
        </div>

        <PartnersMarquee />
      </div>
    </div>
  );
};

export default HeroSection;
